import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { ChakraProvider } from '@chakra-ui/react';

import rootReducer from './reducers';
import { ActionTypes } from './actions';

import App from './components/app';

// this creates the store with the reducers
const store = configureStore({
  reducer: rootReducer,
});

const token = localStorage.getItem('token');
if (token) {
  store.dispatch({ type: ActionTypes.AUTH_USER });
}

const root = createRoot(document.getElementById('main'));
root.render(

  <ChakraProvider>
    <Provider store={store}>
      <App />
    </Provider>,
  </ChakraProvider>,

);

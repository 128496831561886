import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updatePost } from '../actions';
import withRouter from './withRouter';

function Example(props) {
  const [show, setShow] = useState(false);

  const handleClose = (value) => { setShow(false); };
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Add posts
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => {
            e.preventDefault();
            // eslint-disable-next-line no-unused-vars
            const postData = {
              title: e.target.title.value,
              content: e.target.content.value,
              coverUrl: e.target.coverUrl.value,
              tags: e.target.tags.value,
            };
            console.log(postData, '[form data');
            props.updatePost(postData, props.id, props.navigate);
          }}
          >
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control name="title" as="textarea" rows={1} defaultValue={props.post.title} autoFocus />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Content</Form.Label>
              <Form.Control name="content" as="textarea" rows={3} defaultValue={props.post.content} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Cover Pic</Form.Label>
              <Form.Control name="coverUrl" as="textarea" rows={3} defaultValue={props.post.coverUrl} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Tags</Form.Label>
              <Form.Control name="tags" as="textarea" rows={1} defaultValue={props.post.tags} />
            </Form.Group>

            <Button type="submit"
              variant="primary"
              onClick={
              () => {
                handleClose(true);
              }
}
            >
              Save Changes
            </Button>

            <Button variant="secondary"
              onClick={() => {
                handleClose(false);
              }}
            >
              Cancel
            </Button>

          </Form>
        </Modal.Body>

      </Modal>
    </>
  );
}

export default withRouter(connect(null, { updatePost })(Example));

/* eslint-disable no-param-reassign */
/* eslint-disable no-empty */
// eslint-disable-next-line no-unused-vars
import { produce, enableAllPlugins } from 'immer';
import { ActionTypes } from '../actions';

enableAllPlugins();

// eslint-disable-next-line no-unused-vars
const initialState = {
  all: [],
  current: {},
};

const PostsReducer = produce((draftState, action = {}) => {
  switch (action.type) {
    case (ActionTypes.FETCH_POSTS):
      draftState.all = action.payload;
      break;
    case (ActionTypes.CREATE_POST):
      break;
    case (ActionTypes.FETCH_POST):
      draftState.current = action.payload;
      break;
    case (ActionTypes.UPDATE_POST):
      break;
    case (ActionTypes.ERROR_SET):
      console.log(`error reached ${action.error}`);
      break;
    default:
      break;
  }
}, initialState);

export default PostsReducer;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CardGroup } from 'react-bootstrap';

import { Grid } from '@mui/material';
import { fetchPosts, fetchPost } from '../actions';
// eslint-disable-next-line no-unused-vars
import Post from './post';
import withRouter from './withRouter';
import NewCard from './newcard';

class Posts extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.fetchPosts();
  }

  // <div  className="post_container" key={post.id}>{post.title}</div>

  // eslint-disable-next-line react/no-unused-class-component-methods
  _render() {
    const num = 0;
    if (this.props.posts === []) {
      return <div>Fetching posts</div>;
    } else {
      return (
        <div className="card-group">
          {(this.props.posts.map(
            (post) => (
              <div className="card-container" key={post.id}>
                <NewCard navigate={this.props.navigate} post={post} className="card-class" />
              </div>

            ),
          ))}
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        Notes
        {/* {console.log(this.props.posts)} */}
        {this._render()}
      </div>

    );
  }
}

const mapStateToProps = (reduxState) => ({
  posts: reduxState.posts.all,
});

export default withRouter(connect(mapStateToProps, { fetchPosts, fetchPost })(Posts));

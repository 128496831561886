/* eslint-disable no-param-reassign */
import produce from 'immer';
import { ActionTypes } from '../actions';

const initialState = {
  authenticated: false,
  authorName: null,
};

const AuthReducer = produce((draftState, action = {}) => {
  switch (action.type) {
    case (ActionTypes.AUTH_USER):
      draftState.authenticated = true;
      draftState.authorName = action.username;
      break;
    case (ActionTypes.DEAUTH_USER):
      draftState.authenticated = false;
      draftState.authorName = null;
      break;
    case (ActionTypes.AUTH_ERROR):
      draftState.authenticated = false;
      console.log('Auth Error -> ', action.message);
      break;
    default:
      break;
  }
}, initialState);

export default AuthReducer;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import withRouter from './withRouter';
import { deletePost, fetchPost } from '../actions';
// eslint-disable-next-line no-unused-vars
import Example from './modal';

class Post extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      // note: this.props.note,
    };
  }

  componentDidMount() {
    this.props.fetchPost(this.props.params.postID, this.props.navigate);
  }

  render() {
    return (
      <div>
        <div>{this.props.post.title}</div>
        <div>{this.props.post.tags}</div>
        {this.props.post.author
          && (
            <div>{this.props.post.author.username}</div>
          )}
        <ReactMarkdown>{this.props.post.content || ''}</ReactMarkdown>
        <div>{this.props.post.content}</div>
        <div>{this.props.post.coverUrl}</div>
        <Button variant="primary"
          onClick={
            () => { this.props.deletePost(this.props.post._id, this.props.navigate); }
          }
        />
        <Example post={this.props.post} id={this.props.params.postID} />

      </div>

    );
  }
}

const mapStateToProps = (reduxState) => ({
  post: reduxState.posts.current,
});

export default withRouter(connect(mapStateToProps, { deletePost, fetchPost })(Post));

/* eslint-disable react/prefer-stateless-function */
import { connect } from 'react-redux';
import React, { Component } from 'react';
import {
  // eslint-disable-next-line no-unused-vars
  BrowserRouter, Routes, Route, NavLink, useParams,
} from 'react-router-dom';
import withRouter from './withRouter';
import { signoutUser } from '../actions';

class NavBar extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    // let button;
    // if (this.props.auth) {

    // }
    return (
      <nav>
        <ul>
          <li><NavLink to="/" exact="true">Posts</NavLink></li>
          <li><NavLink to="/posts/new" exact="true">New Post</NavLink></li>
          <li><NavLink to="/signin" exact="true">SignIn</NavLink></li>
          <li><NavLink to="/signup" exact="true">SignUp</NavLink></li>
          {this.props.auth > 0
        && (
        <li><NavLink onClick={() => { this.props.signoutUser(this.props.navigate); }} to="" exact="true">Logout</NavLink></li>
        )}

        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  auth: reduxState.auth.authenticated,
});

export default withRouter(connect(mapStateToProps, { signoutUser })(NavBar));

/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import {
  Form, Button, InputGroup, Row, Col,
} from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { createPost } from '../actions';
import withRouter from './withRouter';

class NewPost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      validated: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      return;
    }

    const postData = {
      title: e.target.title.value,
      content: e.target.content.value,
      coverUrl: e.target.coverUrl.value,
      tags: e.target.tags.value,
    };

    this.props.createPost(postData, this.props.navigate);

    this.state.validated = true;
  };

  render() {
    return (
      <Form
        noValidate
        validated={this.state.validated}
        onSubmit={this.handleSubmit}
      >
        <Form.Group className="mb-3" controlId="validationCustom01">
          <Form.Label>Title</Form.Label>
          <Form.Control required type="text" name="title" placeholder="Enter Title" />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Please choose a title.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="validationCustom02">
          <Form.Label>Content</Form.Label>
          <Form.Control required type="text" name="content" placeholder="Enter Content" />
          <Form.Text className="text-muted">
            We never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-2" controlId="validationCustom01">
          <Form.Label>Cover Url</Form.Label>
          <Form.Control required type="text" name="coverUrl" placeholder="PhotoUrl" />
        </Form.Group>

        <Form.Group className="mb-2" controlId="">
          <Form.Label>Tags</Form.Label>
          <Form.Control required type="text" name="tags" placeholder="Enter Tags" />
          <Form.Text className="text-muted">
            Cater to your audience
          </Form.Text>
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>

    );
  }
}

// export default (NewPost);
export default withRouter(connect(null, { createPost })(NewPost));

// Sample card from Airbnb

import React from 'react';
import { Box, Image, Badge } from '@chakra-ui/react';
import withRouter from './withRouter';

// layout and themeing inspiration were drawn from material.ui react framework

function NewCard(props) {
  return (
    // eslint-disable-next-line no-undef
    <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" onClick={() => { props.navigate(`/posts/${props.post._id}`); }}>
      <Image src={props.post.coverUrl} />

      <Box p="6">
        <Box display="flex" alignItems="baseline">
          <Badge borderRadius="full" px="2" colorScheme="teal">
            Post
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            ml="2"
          >
            {/* beds &bull; baths */}
            {props.post.tags.split(' ').map(
              // eslint-disable-next-line array-callback-return
              // eslint-disable-next-line no-useless-concat
              (element) => { return (`${element} `); },
            )}
          </Box>
        </Box>

        <Box
          mt="1"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          isTruncated
        >
          {props.post.title}
          {props.post.author.username}
        </Box>
      </Box>
    </Box>
  );
}
export default withRouter(NewCard);

/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import {
  Form, Button, InputGroup, Row, Col,
} from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { signupUser } from '../actions';
import withRouter from './withRouter';
import post from './post';

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      validated: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      return;
    }

    const postData = {
      email: e.target.email.value,
      password: e.target.password.value,
      username: e.target.username.value,
    };

    // Todo: add sign in action creator
    this.props.signupUser(postData, this.props.navigate);

    this.state.validated = true;
  };

  render() {
    return (
      <Form
        noValidate
        validated={this.state.validated}
        onSubmit={this.handleSubmit}
      >
        <Form.Group className="mb-3" controlId="validationCustom01">
          <Form.Label>Email</Form.Label>
          <Form.Control required type="text" name="email" placeholder="Enter Email" />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Please choose an Email.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="validationCustom01">
          <Form.Label>Username</Form.Label>
          <Form.Control required type="text" name="username" placeholder="Enter Username" />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Please choose a username.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="validationCustom02">
          <Form.Label>Password</Form.Label>
          <Form.Control required type="text" name="password" placeholder="Enter Password" />
          <Form.Text className="text-muted">
            We never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>

    );
  }
}

// export default (NewPost);
export default withRouter(connect(null, { signupUser })(SignUp));

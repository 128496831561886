import React from 'react';
// import { createRoot } from 'react-dom/client';
import '../style.scss';
import {
  // eslint-disable-next-line no-unused-vars
  BrowserRouter, Routes, Route, NavLink, useParams,
} from 'react-router-dom';

import NewPost from './newpost';
import Post from './post';
import Posts from './posts';
import SignIn from './signin';
import SignUp from './signup';
import NavBar from './navbar';
import RequireAuth from './requireAuth';

function FallBack(props) {
  return <div>URL Not Found</div>;
}
function App(props) {
  return (
    <BrowserRouter>
      <div>
        <NavBar />
        <Routes>
          <Route path="/" element={<Posts />} />
          <Route path="/posts/new" element={<RequireAuth><NewPost /></RequireAuth>} />
          <Route path="/posts/:postID" element={<Post />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="*" element={<FallBack />} />
          <Route render={() => (<div>post not found </div>)} />
        </Routes>
      </div>
    </BrowserRouter>

  );
}

export default App;
